<template>
  <v-container>
    <v-row v-if="ready">
      <spinner style="margin: 300px 500px;"></spinner>
    </v-row>

    <div v-else>
      <div class="ml-n5">
        <v-breadcrumbs
          :items="items"
          :to="items.link"
          divider=" - "
        ></v-breadcrumbs>
      </div>
      <v-card
        height="400"
        outlined
        class="d-flex flex-wrap justify-space-around mb-6"
      >
        <div>{{ products.name }}</div>
        <v-divider vertical></v-divider>
        <div
          style="display: flex; flex-direction: row; justify-content: space-around; padding: 10px 10px"
        >
          <div style="width: 200px"><h5>Product Description:</h5></div>
          <div style="width: 100px">
            <h5>{{ products.description }}</h5>
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import * as axios from "axios";
import Spinner from "vue-simple-spinner";

export default {
  name: "ProductsDetails",
  components: { spinner: Spinner },
  data: () => ({
    products: {},
    ready: true,
    name: "",
    description: "",
    items: [
      {
        text: "Products",
        disabled: false,
        name: "master.products"
      },
      {
        text: "Product",
        disabled: false
      }
    ]
  }),
  methods: {
    getProduct() {
      let id = this.$route.params.id;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/products/${id}`
      })
        .then(response => {
          console.log(response);
          if (response.status === 200) {
            this.products = response.data.data;
            this.ready = false;
            //console.log(response);
          }
        })
        .catch(err => {
          console.log(err);
          if (err.response.status === 401) {
            this.$router.replace({ name: "/login" });
          } else {
            //console.log(err);
          }
        });
    }
  },
  created() {
    this.getProduct();
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  maring-right: -10px;
}

.stretch-card {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.grid-margin {
  margin-bottom: 20px;
}

.card {
  border: 2px;
  border-radius: 2px;
  display: flex;
  height: 400px;
  flex-direction: column;
  background-color: #fff;
  background-clip: border-box;
}
.card2 {
  border: 2px;
  border-radius: 2px;
  display: flex;
  height: 180px;
  width: 560px;
  margin-top: -10px;
  flex-direction: column;
  background-color: #fff;
  background-clip: order-box;
}
.block {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.extreme {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
}
.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}
.bottom {
  border-bottom: 1px solid #aacfcf;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  color: #2f4053;
}
.button {
  background-color: #04a9f3;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.button1 {
  background-color: #1eb2a6;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.button2 {
  background-color: #ffa3ad;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.button3 {
  background-color: #b590ca;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
#app {
  margin: 0 auto;
  padding: 20px;
  max-width: 600px;
}

.bitcoin-price {
  .vtc {
    height: 250px;
    font-size: 12px;
    @media (min-width: 699px) {
      height: 350px;
    }
  }
  .grid,
  .labels {
    line {
      stroke: rgba(#f69119, 0.5);
    }
  }
  .x-labels {
    .label {
      text {
        display: none;
      }
      line {
        opacity: 0.3;
      }
      &:nth-child(6n + 1),
      &:first-child {
        text {
          display: block;
        }
        line {
          opacity: 1;
        }
      }
    }
  }
  .curve-btc {
    .stroke {
      stroke: #f69119;
      stroke-width: 2;
    }
    .fill {
      fill: url(#btcFill);
      fill-opacity: 0.5;
    }
  }
}
</style>
